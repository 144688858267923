<template>
  <div class="container mt-2">
    <div>
      <h4>
        <router-link :to="{ name: 'home' }">
          <i class="fas fa-chevron-circle-left fa-lg" />
        </router-link>
        ประวัติการทำรายการ
      </h4>
    </div>
    <div>
      <b-row class="tab-select">
        <b-col
          lg="6"
          cols="6"
        >
          <button
            variant="primary"
            :class="`btn ${Status === 'Diposit' ? 'active' : ''}`"
            @click="ShowDiposit()"
          >
            <i class="fas fa-arrow-down" />
            รายการฝาก
          </button>
        </b-col>

        <b-col
          lg="6"
          cols="6"
        >
          <button
            variant="warning"
            :class="`btn ${Status === 'Withdraw' ? 'active' : ''}`"
            @click="ShowWithdraw()"
          >
            <i class="fas fa-arrow-up" />
            รายการถอน
          </button>
        </b-col>
      </b-row>

      <div class="text-center mt-2">
        <h3>{{ StatusName }}</h3>
      </div>
      <div>
        <b-row class="text-center pd">
          <b-col cols="1">
            #
          </b-col>
          <b-col cols="4">
            ยอดเงิน
          </b-col>
          <b-col cols="4">
            เวลา
          </b-col>
          <b-col cols="3">
            สถานะ
          </b-col>
        </b-row>
      </div>

      <div v-if="ListData.length > 0">
        <div
          v-for="(item, index) in ListData"
          :key="item._id"
        >
          <div class="status-list mb-1">
            <b-row class="text-center pd">
              <b-col cols="1">
                {{ index + 1 }}
              </b-col>
              <b-col cols="4">
                {{ Commas(item.amount) }}
              </b-col>
              <b-col cols="4">
                {{ CalToTime(item.created_at) }}
                น.
              </b-col>
              <b-col cols="3">
                <b-badge
                  v-if="Status === 'Withdraw'"
                  :variant="
                    item.status === 'waiting'
                      ? 'light-warning'
                      : item.status === 'hold_success'
                        ? 'light-success'
                        : item.status === 'waiting_approve'
                          ? 'light-secondary'
                          : item.status === 'success'
                            ? 'light-success'
                            : item.status === 'processing'
                              ? 'light-warning'
                              : item.status === 'restore'
                                ? 'light-dark'
                                : 'light-danger'
                  "
                >
                  {{
                    item.status === 'waiting'
                      ? 'รอทำรายการ'
                      : item.status === 'hold_success'
                        ? 'ยึดเครดิต'
                        : item.status === 'waiting_approve'
                          ? 'รอยืนยัน'
                          : item.status === 'success'
                            ? 'สำเร็จ'
                            : item.status === 'processing'
                              ? 'กำลังดำเนินการ'
                              : item.status === 'restore'
                                ? 'คืนเงิน'
                                : 'ไม่สำเร็จ'
                  }}
                </b-badge>
                <b-badge
                  v-if="Status === 'Diposit'"
                  :variant="
                    item.status === 'success'
                      ? 'light-success'
                      : item.status === 'pending'
                        ? 'light-warning'
                        : 'light-danger'
                  "
                >
                  {{
                    item.status === 'success'
                      ? 'สำเร็จ'
                      : item.status === 'pending'
                        ? 'กำลังดำเนินการ'
                        : 'ไม่สำเร็จ'
                  }}
                </b-badge>
              </b-col>
            </b-row>
          </div>
        </div>
      </div>

      <div
        v-else
        class="text-center mt-2"
      >
        <h3>ไม่พบรายการฝาก</h3>
      </div>
    </div>
  </div>
</template>

<script>
import { BRow, BCol, BBadge } from 'bootstrap-vue'
import momenttz from 'moment-timezone'

export default {
  components: { BRow, BCol, BBadge },
  data() {
    return {
      message: '',
      Status: 'Diposit',
      StatusName: 'สถานะการฝาก 5 รายการล่าสุด',
      ListData: [],
    }
  },
  mounted() {
    this.ShowDiposit()
  },
  methods: {
    async ShowDiposit() {
      try {
        const { data: response } = await this.$http.get(
          'https://api.ma5lotto.com/api/wallet/deposit/show',
        )
        if (response && response.success === true) {
          this.Status = 'Diposit'
          this.StatusName = 'สถานะการฝาก 5 รายการล่าสุด'
          this.ListData = response.Data
        }
      } catch (err) {
        console.log(err)
      }
    },
    async ShowWithdraw() {
      try {
        const { data: response } = await this.$http.get(
          'https://api.ma5lotto.com/api/wallet/withdraw/show',
        )
        if (response && response.success === true) {
          this.Status = 'Withdraw'
          this.StatusName = 'สถานะการถอน 5 รายการล่าสุด'
          this.ListData = response.Data
        }
      } catch (err) {
        console.log(err)
      }
    },
    CalToTime(Val) {
      return momenttz(Val).tz('Asia/Bangkok').format('HH:mm')
    },
    Commas(x) {
      if (Math.round(x) !== x && x > 0) {
        const Num = Number(x)
        const y = Num.toFixed(2)
        return y.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    showToast1() {
      this.$toast.success('คัดลอกเลขบัญชีสำเร็จ', {
        position: 'top-right',
        timeout: 2000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: 'button',
        icon: 'far fa-copy',
        rtl: false,
      })
    },
  },
}
</script>

<style scoped>
.pd {
  padding: 10px 5px;
}
.status-list {
  /* border: 1px solid red; */
  border-radius: 30px;
  background: #fff;
  box-shadow: 2px 2px 5px rgb(219, 186, 186);
  /* padding: 10px; */
}
.depo {
  border: 1px solid rgb(190, 190, 190);
  border-radius: 5px;
  padding: 10px;
  box-shadow: 2px 2px 10px rgb(212, 212, 212);
}
.img-bank {
  margin: auto;
  background: rgb(71, 1, 104);
  padding: 5px;
  height: 160px;
  width: 160px;
  border-radius: 50%;
}
.notice__list {
  font-size: 18px;
}
.notice__list {
  background-color: #fff;
  border: 1px solid rgb(255, 0, 0);
  padding: 15px 24px;
  border-radius: 20px;
  margin-bottom: 0.75rem;
}
.notice__list p.alert {
  display: flex;
}
.btn-copy-2 {
  color: #000000;
  cursor: pointer;
  position: relative;
  font-size: 1.4rem;
}
.btn {
  border-radius: 38px;
  color: rgb(255, 255, 255);
  width: 100%;
  background-color: transparent;
}
.btn:hover {
  background-color: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
}
.btn:focus {
  background-color: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
}

.active,
.btn:focus {
  background-color: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
}
.balance-cl {
  margin: auto;
  background-color: #4e2e7f;
  border-radius: 15px;
  padding: 24px;
  box-sizing: border-box;
  max-width: 640px;
  height: 100%;
  color: #000;
  position: relative;
}
.balance-bay {
  background-color: #ffc323;
  border-radius: 15px;
  padding: 24px;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  color: #000;
  position: relative;
}
p.highlight {
  line-height: 1.9;
  font-size: 2.6rem;
}
.pic {
  max-width: 210px;
}
@media only screen and (max-width: 600px) {
  .pic {
    margin-top: 35px;
    max-width: 160px;
  }
  .pic2 {
    margin-top: 35px;
    max-width: 40px;
  }
}
.neon1 {
  background: rgb(27, 12, 27);
  background: radial-gradient(circle, rgba(27, 12, 27, 0) 0%, #09081f 100%);
  border-radius: 5px;
  padding: 5px 5px;
  /* border: 1px solid blueviolet; */
  /* box-shadow: blueviolet 1px 1px 15px; */
}
.m-space {
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 35px;
  margin-left: 1px;
  margin-right: 1px;
}
.tab-select {
  background-color: #b11717;
  box-shadow: 2px 2px 5px rgb(219, 186, 186);
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 30px;
}
</style>
